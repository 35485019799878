import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import Col from '../../common/containers/Col';
import Row from '../../common/containers/Row';
import LocaleUtils from '../../common/LocaleUtils';
import URLS from '../../urls';
import Modal from '../../common/containers/Modal';
import PayWithMercadoPago from '../payment/PayWithMercadoPagoV2';
import PayPalButton from '../payment/PayPalButton';

class PlanFeature extends Component {
  render() {
    return (
      <li title={this.props.title} style={{ textDecoration: this.props.enabled ? '' : 'line-through' }}>
        {this.props.enabled ? (
          <i className="text-success fas fa-check">&nbsp;</i>
        ) : (
          <i className="text-danger fas fa-close">&nbsp;</i>
        )}
        {this.props.name}
        {this.props.children}
      </li>
    );
  }
}

PlanFeature.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  icon: PropTypes.string,
  iconElement: PropTypes.element,
  enabled: PropTypes.bool.isRequired,
};

class Plan extends Component {
  constructor(props) {
    super(props);
    this.state = { showPaymentOptions: '' };
  }

  getSharingName() {
    const { i18n, plan } = this.props;
    if (plan.has_unlimited_authors) {
      return i18n.t('Compartilhamento ilimitado');
    }
    const shares = plan.author_limit;
    return `${shares} ${i18n.t('compartilhamentos')}`;
  }

  getEconomyLabel() {
    const { plan, isLast } = this.props;
    if (plan.economy_percent > 0) {
      const { i18n } = this.props;
      return (
        <p>
          {this.props.isLast && (
            <>
              <span className="icon mdi mdi-flash text-warning" />{' '}
            </>
          )}
          <span className={isLast ? 'text-danger' : ''} style={{ fontWeight: isLast ? 'bold' : '' }}>
            {i18n.t('Economia de')} {plan.economy_percent}% {i18n.t('em comparação ao plano Bronze')}
          </span>
        </p>
      );
    } else {
      return <p className="label">&nbsp;</p>;
    }
  }

  getPriceInfo() {
    if (!this.props.showActionLinks) {
      return null;
    }
    const { i18n, plan, currencyLabel } = this.props;

    const price = LocaleUtils.number(plan.price_per_month);
    let price_int = null;
    let price_cents = null;

    if (price.indexOf('.') > -1) {
      price_int = price.split('.')[0];
      price_cents = price.split('.')[1].padEnd(2, '0');
    } else if (price.indexOf(',') > -1) {
      price_int = price.split(',')[0];
      price_cents = price.split(',')[1].padEnd(2, '0');
    } else {
      price_int = price;
    }

    return (
      <React.Fragment>
        {plan.price_per_month > 0 && (
          <>
            <span className="text-muted">{i18n.t('Custo equivalente a')}</span>
            <div className="pricing-table-price text-success">
              {/* <span className="currency">{currencyLabel}</span>
              <span className="value">{LocaleUtils.number(plan.price_per_month)}</span>
              <span className="frecuency">/{i18n.t('mês')}</span> */}
              <span style={{ fontSize: '12pt' }}>{currencyLabel}</span>
              <span style={{ fontSize: '40pt' }}>{price_int}</span>

              <span
                style={{
                  fontSize: '13pt',
                  width: '30px',
                  display: 'inline-block',
                  textAlign: 'left',
                  lineHeight: '20px',
                }}
              >
                {price_cents && <b>,{price_cents}</b>} /{i18n.t('mês')}
              </span>
            </div>
          </>
        )}
      </React.Fragment>
    );
  }

  getLinkToPayPage() {
    const { plan, i18n } = this.props;
    return (
      <Link to={URLS.pay(plan.id)} className="btn btn-success btn-lg btn-block">
        {i18n.t('Comprar')}
      </Link>
    );
  }

  getActionLinks() {
    if (!this.props.showActionLinks) {
      return null;
    }
    const { i18n, plan, currencyCode } = this.props;

    return (
      <>
        {plan.price_per_month > 0 && (
          <Row>
            <Col md={8} mdOffset={2}>
              {this.getLinkToPayPage()}
              {/* Button pra abrir pagamento em forma de modal */}
              {/* <button
                className="btn btn-success btn-lg btn-block"
                role="button"
                onClick={() => this.setState((state) => ({ ...state, showPaymentOptions: 'mercadopago' }))}
              >
                {i18n.t('Comprar')}
              </button> */}
            </Col>
          </Row>
        )}

        {plan.price_per_month > 0 && (
          <p className="text-muted" style={{ fontWeight: 400, fontSize: '12px', marginTop: '10px' }}>
            {i18n.t('Pagamento à vista de')} {LocaleUtils.currency(plan.price, currencyCode)}
            <br />
            {i18n.t('ou parcelado no cartão de crédito c/ juros')}
          </p>
        )}
      </>
    );
  }

  render() {
    const { i18n, plan, isLast } = this.props;

    if (!plan) {
      return null;
    }

    const planName = plan.price_per_month ? plan.name : i18n.t('Grátis');

    return (
      <>
        <div className={`pricing-table ${isLast ? 'pricing-table-success' : ''}`}>
          {/*<div className="pricing-table-image"/>*/}
          <div className="pricing-table-title">
            <b>{planName.toUpperCase()}</b>
            {plan.upgrade_promotion && (
              <span className="text-warning">
                <br />
                <i className="fas fa-star" /> upgrade!
              </span>
            )}
          </div>

          <div className="panel-divider panel-divider-xl" />

          {plan.expiration_days > 0 && (
            <h3>
              {plan.expiration_label} {i18n.t('de uso')}
            </h3>
          )}

          {this.getPriceInfo()}

          {this.getEconomyLabel()}

          <ul className="pricing-table-features" style={{ marginTop: '10px' }}>
            <PlanFeature
              title={i18n.t('Armazenamento')}
              name={`${i18n.t('Armazenamento')} ${plan.storage_limit}MB`}
              enabled={true}
              icon="fas fa-storage"
            />

            <PlanFeature
              title={i18n.t('Veja e compare versões anteriores dos seus documentos')}
              name={i18n.t('Histórico de Alterações')}
              enabled={plan.has_history}
              icon="fas fa-history"
            />

            <PlanFeature
              title={i18n.t('Importe documentos no formato docx.')}
              name={i18n.t('Importar arquivo Word (docx)')}
              icon="fas fa-book"
              enabled={plan.can_import_doc}
            />

            <PlanFeature
              title={i18n.t('Compartilhe e edite seus documentos com usuários do FastFormat')}
              name={this.getSharingName()}
              icon="fas fa-users"
              enabled={plan.has_sharing}
            />

            <PlanFeature
              title={i18n.t('Corretor ortográfico para diferentes idiomas')}
              enabled={plan.has_spellchecker}
              name={i18n.t('Corretor ortográfico')}
              icon="fas fa-language"
            />

            <PlanFeature
              title={i18n.t('Corretor gramatical e de estilo')}
              name={i18n.t('Corretor gramatical e de estilo')}
              enabled={plan.has_proofread}
            />

            <PlanFeature
              title={i18n.t(
                'Crie e compartilhe revisões para seu documento onde outras pessoas poderão inserir comentários',
              )}
              enabled={plan.has_reviewing}
              name={i18n.t('Revisão em pares')}
              icon="fas fa-comments"
            />

            <PlanFeature
              title={i18n.t('Faça anotações nos arquivos PDF das referências.')}
              name={i18n.t('Anotações em referências')}
              icon="fas fa-bookmark-o"
              enabled={plan.has_reference_annotation}
            />

            <PlanFeature
              title={i18n.t('Edite suas imagens diretamente no editor do FastFormat.')}
              name={i18n.t('Edição de imagens')}
              icon="fas fa-book"
              enabled={plan.has_image_editor}
            />

            <PlanFeature
              title={i18n.t('Exporte seus documentos para o formato E-book.')}
              name={i18n.t('Exportar para E-book')}
              icon="fas fa-book"
              enabled={plan.has_ebook}
            />
          </ul>

          {!plan.price_per_month && plan.page_limit > 0 && (
            <p>
              {i18n.t("Páginas sem marca d'água")}:<br /> {plan.page_limit / 2} {i18n.t('para artigos e')}{' '}
              {plan.page_limit} {i18n.t('para outros documentos')}
            </p>
          )}

          {this.getActionLinks()}
        </div>

        {this.state.showPaymentOptions && (
          <Modal
            title={i18n.t('Realizar pagamento') + ' - ' + i18n.t('plano') + ' ' + plan.name}
            show={true}
            onCancel={() => this.setState((state) => ({ ...state, showPaymentOptions: '' }))}
            width="large"
            headerFullColor="primary"
            bodyStyle={{ padding: '0px 25px' }}
            footerStyle={{ paddingTop: '5px', paddingBottom: '5px', textAlign: 'left' }}
            footer={
              (this.state.showPaymentOptions === 'mercadopago' && (
                <>
                  <i className="mdi mdi-globe" /> Está fora da América Latina, pague com{' '}
                  <a
                    role="button"
                    onClick={() => this.setState((state) => ({ ...state, showPaymentOptions: 'paypal' }))}
                  >
                    PayPal
                  </a>
                  . Problemas para realizar o pagamento? Tente <a href={`${plan.id}/pay`}>outros meios</a>.
                </>
              )) || <p />
            }
          >
            {this.state.showPaymentOptions === 'mercadopago' && (
              <PayWithMercadoPago
                plan={plan}
                // onProcessing={this.onProcessing}
                // amount={this.props.location.amount}
              />
            )}

            {this.state.showPaymentOptions === 'paypal' && (
              <PayPalButton
                plan={plan}
                // onProcessing={this.onProcessing}
              />
            )}
          </Modal>
        )}
      </>
    );
  }
}

Plan.propTypes = {
  plan: PropTypes.object.isRequired,
  isLast: PropTypes.bool.isRequired,
  showActionLinks: PropTypes.bool.isRequired,
  currencyCode: PropTypes.string.isRequired,
  currencyLabel: PropTypes.string.isRequired,
};

Plan.defaultProps = {
  showActionLinks: true,
};

export default withNamespaces()(Plan);
