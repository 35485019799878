import React, {Component} from 'react';
import Content from "../../common/containers/Content";
import PublicPage from "../../common/containers/PublicPage";
import {withNamespaces} from "react-i18next";
import Plans from "./Plans";


class PlansPage extends Component {
    constructor(props) {
        super(props);
        window._marketingMsgs = null;
    }

    componentDidMount() {
        if (window.innerWidth > 700) {
            if (!window._marketingMsgs) {
                import("./MarketingUpdates").then(MarketingUpdates => {
                    window._marketingMsgs = new MarketingUpdates.default;
                    window._marketingMsgs.start();
                });
            } else {
                window._marketingMsgs.stop();
                window._marketingMsgs.start();
            }
        }
    }

    componentWillUnmount() {
        if (window._marketingMsgs) {
            window._marketingMsgs.stop();
        }
    }

    render() {
        const {i18n} = this.props;
        return (
            <PublicPage
                hasAside={false}
                title={i18n.t('Preços')}
                leftSidebarCollapsed={true}
            >
                <Content>
                    <Plans/>
                </Content>
            </PublicPage>
        );
    }
}

export default withNamespaces()(PlansPage);
