import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import Col from '../../common/containers/Col';
import Row from '../../common/containers/Row';
import LocaleUtils from '../../common/LocaleUtils';
import URLS from '../../urls';

class PlanFeature extends Component {
  render() {
    return (
      <li title={this.props.title} style={{ textDecoration: this.props.enabled ? '' : 'line-through' }}>
        {this.props.enabled ? (
          <i className="text-success fas fa-check">&nbsp;</i>
        ) : (
          <i className="text-danger fas fa-close">&nbsp;</i>
        )}
        {this.props.name}
        {this.props.children}
      </li>
    );
  }
}

PlanFeature.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  icon: PropTypes.string,
  iconElement: PropTypes.element,
  enabled: PropTypes.bool.isRequired,
};

class SinglePlan extends Component {
  getSharingName() {
    const { i18n, plan } = this.props;
    if (plan.has_unlimited_authors) {
      return i18n.t('Compartilhamento ilimitado');
    }
    const shares = plan.author_limit;
    return `${shares} ${i18n.t('compartilhamentos')}`;
  }

  getEconomyLabel() {
    const { plan, isLast } = this.props;
    if (plan.economy_percent > 0) {
      const { i18n } = this.props;
      return (
        <p>
          {this.props.isLast && (
            <>
              <span className="icon mdi mdi-flash text-warning" />{' '}
            </>
          )}
          <span className={isLast ? 'text-danger' : ''} style={{ fontWeight: isLast ? 'bold' : '' }}>
            {i18n.t('Economia de')} {plan.economy_percent}% {i18n.t('em comparação ao plano Bronze')}
          </span>
        </p>
      );
    } else {
      return <p className="label">&nbsp;</p>;
    }
  }

  getPriceInfo() {
    if (!this.props.showActionLinks) {
      return null;
    }
    const { i18n, plan, config } = this.props;

    const price = LocaleUtils.number((plan.price / (config.interest_free_instalments || 1)).toFixed(2));
    let price_int = null;
    let price_cents = '00';

    if (price.indexOf('.') > -1) {
      price_int = price.split('.')[0];
      price_cents = price.split('.')[1].padEnd(2, '0');
    } else if (price.indexOf(',') > -1) {
      price_int = price.split(',')[0];
      price_cents = price.split(',')[1].padEnd(2, '0');
    } else {
      price_int = price;
    }

    return (
      <>
        <div className="pricing-table-price text-success">
          <span style={{ fontSize: '12pt' }}>{config.currency_label}</span>
          <span style={{ fontSize: '55pt' }}>{price_int}</span>
          <span
            style={{
              fontSize: '15pt',
              width: '30px',
              display: 'inline-block',
              textAlign: 'left',
              lineHeight: '35px',
            }}
          >
            {price_cents && <b>,{price_cents}</b>} &nbsp;
          </span>
        </div>
      </>
    );
  }

  getActionLinks() {
    if (!this.props.showActionLinks) {
      return null;
    }
    const { i18n, plan, config } = this.props;

    return (
      <>
        {plan.price_per_month > 0 && (
          <Row>
            <Col md={8} mdOffset={2}>
              <Link to={URLS.pay(plan.id)} className="btn btn-success btn-lg btn-block">
                {i18n.t('Comprar')}
              </Link>
            </Col>
          </Row>
        )}

        <p className="text-muted" style={{ fontWeight: 400, fontSize: '12px', marginTop: '10px' }}>
          {i18n.t('Valor final de')} {LocaleUtils.currency(plan.price, config.currency_code)}{' '}
          {config.interest_free_instalments && (
            <>
              {i18n.t('que pode ser parcelado em até')} {config.interest_free_instalments}x{' '}
              {i18n.t('sem juros no cartão de crédito')}
            </>
          )}
          {!config.interest_free_instalments && i18n.t('ou parcelado no cartão de crédito c/ juros')}.
          <br />
          Você pode pagar com cartão de crédito, cartão de débito, Pix ou boleto.
        </p>
      </>
    );
  }

  render() {
    const { i18n, plan, isLast, config } = this.props;

    if (!plan) {
      return null;
    }

    return (
      <React.Fragment>
        <div className={`pricing-table ${isLast ? 'pricing-table-success' : ''}`}>
          {plan.expiration_days > 0 && <h2>{i18n.t('Adquira todos os benefícios do FastFormat')}</h2>}

          <Row>
            <Col md={4}>
              <ul className="pricing-table-features" style={{ marginTop: '10px' }}>
                <PlanFeature
                  title={
                    <>
                      {plan.expiration_label} {i18n.t('de uso')}
                    </>
                  }
                  name={
                    <>
                      {plan.expiration_label} {i18n.t('de uso')}
                    </>
                  }
                  enabled={true}
                  icon="fas fa-storage"
                />
                <PlanFeature
                  title={i18n.t('Armazenamento')}
                  name={`${i18n.t('Armazenamento')} ${plan.storage_limit}MB`}
                  enabled={true}
                  icon="fas fa-storage"
                />
                <PlanFeature
                  title={i18n.t('Veja e compare versões anteriores dos seus documentos')}
                  name={i18n.t('Histórico de Alterações')}
                  enabled={plan.has_history}
                  icon="fas fa-history"
                />
                <PlanFeature
                  title={i18n.t('Importe documentos no formato docx.')}
                  name={i18n.t('Importar arquivo Word (docx)')}
                  icon="fas fa-book"
                  enabled={plan.can_import_doc}
                />
              </ul>
            </Col>
            <Col md={4}>
              <ul className="pricing-table-features" style={{ marginTop: '10px' }}>
                <PlanFeature
                  title={i18n.t('Compartilhe e edite seus documentos com usuários do FastFormat')}
                  name={this.getSharingName()}
                  icon="fas fa-users"
                  enabled={plan.has_sharing}
                />

                <PlanFeature
                  title={i18n.t('Corretor ortográfico para diferentes idiomas')}
                  enabled={plan.has_spellchecker}
                  name={i18n.t('Corretor ortográfico')}
                  icon="fas fa-language"
                />

                <PlanFeature
                  title={i18n.t('Corretor gramatical e de estilo')}
                  name={i18n.t('Corretor gramatical e de estilo')}
                  enabled={plan.has_proofread}
                />

                <PlanFeature
                  title={i18n.t(
                    'Crie e compartilhe revisões para seu documento onde outras pessoas poderão inserir comentários',
                  )}
                  enabled={plan.has_reviewing}
                  name={i18n.t('Revisão em pares')}
                  icon="fas fa-comments"
                />
              </ul>
            </Col>
            <Col md={4}>
              <ul className="pricing-table-features" style={{ marginTop: '10px' }}>
                <PlanFeature
                  title={i18n.t('Faça anotações nos arquivos PDF das referências.')}
                  name={i18n.t('Anotações em referências')}
                  icon="fas fa-bookmark-o"
                  enabled={plan.has_reference_annotation}
                />

                <PlanFeature
                  title={i18n.t('Edite suas imagens diretamente no editor do FastFormat.')}
                  name={i18n.t('Edição de imagens')}
                  icon="fas fa-book"
                  enabled={plan.has_image_editor}
                />

                <PlanFeature
                  title={i18n.t('Exporte seus documentos para o formato E-book.')}
                  name={i18n.t('Exportar para E-book')}
                  icon="fas fa-book"
                  enabled={plan.has_ebook}
                />
              </ul>
            </Col>
          </Row>

          {!plan.price_per_month && plan.page_limit > 0 && (
            <p>
              {i18n.t("Páginas sem marca d'água")}:<br /> {plan.page_limit / 2} {i18n.t('para artigos e')}{' '}
              {plan.page_limit} {i18n.t('para outros documentos')}
            </p>
          )}

          <h3>
            Pague em até {config.interest_free_instalments && <>{config.interest_free_instalments}x sem juros de</>}
          </h3>

          {this.getPriceInfo()}

          {this.getActionLinks()}
        </div>
      </React.Fragment>
    );
  }
}

SinglePlan.propTypes = {
  plan: PropTypes.object.isRequired,
  isLast: PropTypes.bool.isRequired,
  showActionLinks: PropTypes.bool.isRequired,
  currencyCode: PropTypes.string.isRequired,
  currencyLabel: PropTypes.string.isRequired,
};

SinglePlan.defaultProps = {
  showActionLinks: true,
};

export default withNamespaces()(SinglePlan);
